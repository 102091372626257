import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function purchaseinbounditemUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'product_name', label: '商品名称'},
    { key: 'purchase_team', label: '销售权'},
    { key: 'product_code', label: '编码' },
    { key: 'actions', label: '操作' },
  ]

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  return {
    refListTable,
    tableColumns,
    refetchData,
  }
}
