<template>
  <div>
    <b-card
        no-body
        class="mb-0"
    >
<!--      <b-modal
          id="modal-select-warehouse"
          hide-footer
          centered
          size="xl"
          title="选择仓库12121212"
      >
        <warehouse-list
            ref="warehouseSelect" :type="1" v-on:table="fromChildren($event,'modal-select-warehouse')"
        >
        </warehouse-list>
      </b-modal>-->

      <b-modal
          id="loadingModal"
          centered
          hide-header
          no-close-on-backdrop
          no-close-on-esc
          hide-footer
          ref="loadingModal"
      >
        <p class="my-4">正在处理，请勿关闭浏览器!</p>
      </b-modal>
      <b-modal
          id="modal-select-company"
          ok-only
          ok-title="确认"
          @ok="onSelectSingle"
          cancel-title="取消"
          centered
          size="lg"
          :title="'请选择集团公司'"
          ref="companyModal"
      >
        <company-select
            ref="companySelect" :companyType="'Group'"v-on:table="fromChildren($event,'modal-select-company')"
        >
        </company-select>
      </b-modal>
      <b-modal
          id="modal-select-product"
          ok-only
          ok-title="确认"
          @ok="onSelectProducts"
          cancel-title="取消"
          centered
          size="xl"
          title="选择商品"
      >
        <product-select
            ref="myProductSelect"
        >
        </product-select>
      </b-modal>
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <b-col
              cols="12"
              md="3"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="仓库"
                label-for="warehouse_name"
                class="mb-1 required"
            >
              <v-select
                  id="warehouse_name"
                  :options="warehouseOptions"
                  :clearable="false"
                  v-model="inbound.warehouse_id"
                  @input="onChangeWarehouse($event)"
                  class="select-size-sm"
                  :reduce="option => parseInt(option.value)"
                  :disabled="!canEditFlag"
              >
              </v-select>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <xy-date-picker label-cols="3" label="入库日期" :val.sync="inbound.arrival_time"
                            rules="required" format="time" :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                            id="refund_time" :disabled="!canEditFlag"> </xy-date-picker>
          </b-col>

          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="签约主体名称"
                label-for="company_name"
                label-size="sm"
                class="mb-1 required"
            >
              <ValidationProvider rules="required" name="签约主体名称" #default="{ errors }">
                <b-form-input
                    id="company_name"
                    size="sm"
                    v-model="inbound.company_name"
                    @click="showCompany()"
                    readonly
                    placeholder="点击搜索集团公司"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>

            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              md="3"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-if="canEditFlag"
                  variant="primary"
                  v-b-modal.modal-select-product
              >
                <span class="text-nowrap">添加产品</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>


      <b-table
          ref="refListTable"
          class="position-relative"
          :items="itemList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="未找到记录"
      >

        <template #cell(product_name)="data">
          [#{{ data.item.product_id }}]{{ data.item.product_name }}
        </template>

        <template #cell(inventory_type)="data">
          {{ getCodeLabel('inbound_inventory_type', data.item.inventory_type) }}
        </template>

        <template #cell(purchase_team)="data">
          <v-select
              :options="getCodeOptions('purchase_team')"
              v-model="data.item.purchaseTeam"
              name="user_level"
              class="select-size-sm"
              placeholder="请选择销售权团队"
              style="width: 200px"
              :reduce="option => parseInt(option.value)"
              :disabled="!canEditFlag"
          />
        </template>

        <template #row-details="data">
          <b-card>
            <inbound-form
                :ref="'inboundForm'+data.index" :canEditFlag="canEditFlag" :inbound-item="data.item" :warehouse_id="inbound.warehouse_id"
                v-on:refresh="hideAndRefetchData(data)"
            >
            </inbound-form>
          </b-card>

        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="row">
            <span v-if="canEditFlag">
               <xy-pop-confirm title="确认删除？" @confirm="del(row.index)">
                <xy-link>删除</xy-link>
              </xy-pop-confirm>
            </span>
        </template>
      </b-table>

      <div class="m-2">
        <b-row>
          <b-col md="12">
            <xy-textarea label-cols="1" id="remark" :readonly="!canEditFlag" label="备注" :val.sync="inbound.memo_wh" />
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="附件"
                label-for="attachments"
                label-size="sm"
                class="mb-1"
            >
              <attachment-upload v-if="inbound.loaded"
                                 theme="files"
                                 attachment_id="attachmentsWh"
                                 :id="inbound.attachments_wh"
                                 object_type="purchase_inbound"
                                 placeholder=""
                                 @change="onUploaded"
                                 :readonly="!canEditFlag"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-button
            variant="primary"
            class="mr-1"
            @click="save(1)"
            v-if="newPageFlag||(!newPageFlag&&inbound.status==1)"
        >
          保存
        </b-button>
        <b-button
            variant="primary"
            class="mr-1"
            @click="save(2)"
            v-if="newPageFlag||(!newPageFlag&&inbound.status==1)"
        >
          提交
        </b-button>
        <b-button
            variant="primary"
            class="mr-1"
            @click="approval(3)"
            v-if="!newPageFlag&&inbound.status==2&&(user.role_id === 14 || user.role_id === 18)"
        >
          审核通过
        </b-button>
        <b-button
            variant="primary"
            class="mr-1"
            @click="approval(1)"
            v-if="!newPageFlag&&inbound.status==2&&(user.role_id === 14 || user.role_id === 18)"
        >
          驳回
        </b-button>

        <b-button
            variant="primary"
            class="mr-1"
            @click="cancel"
            v-if="!newPageFlag&&inbound.status==3"
        >
          返回
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAlert,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormInput,
  BLink,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions, isEmpty} from '@core/utils/filter'
import ProductSelect from '@/views/apps/product/product-purchase/ProductSelect'
import inboundEdit from './inboundEdit'
import inboundEditStore from './inboundEditStore'
import { useToast } from 'vue-toastification/composition'
import { useRouter } from '@core/utils/utils'
import InboundForm from '@/views/apps/otherInbound/inboundForm.vue'
// import StockInboundItemList from '@/views/apps/stockinbounditem/inboundItem-modal/StockInboundItemList'
// import WarehouseList from '@/views/apps/warehouse/warehouse-modal/WarehouseList'
import CompanySelect from '@/views/apps/company/CompanySelect'
import XyLink from '@/views/components/xy/XyLink'
import XyPopConfirm from '@/views/components/xy/XyPopConfirm'
import { getUserData } from '@/auth/utils'
import XyTextarea from '@/views/components/xy/XyTextarea'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import XyDatePicker from '@/views/components/xy/XyDatePicker'
import axios from "@/libs/axios";
import {lineChartOptions} from "@core/components/statistics-cards/chartOptions";

export default {
  props: {
    /*inboundId: {
      type: Number,
    },*/
  },
  components: {
    XyDatePicker,
    AttachmentUpload,
    XyTextarea,
    XyPopConfirm,
    XyLink,
    // WarehouseList,
    CompanySelect,
    ProductSelect,
    // StockInboundItemList,
    InboundForm,
    BCard,
    BAlert,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      user: {},
      newPageFlag:true,
      canEditFlag:true,
      inbound: ref({}),
      itemList: [],
    }
  },
  computed:{
    warehouseOptions(){
      return ["1","2"].includes(this.level)?getCodeOptions('warehouse'):getCodeOptions('warehouse').filter(option=>getCodeLabel('user_warehouse',this.user_id).includes(option.value))
    }
  },
  methods: {
    /*selectable(option){
      return getCodeLabel('user_warehouse',this.user_id).includes(option.value)
    },*/
    del(index) {
      this.itemList.splice(index, 1)
    },
    fromChildren(data, modal) {
      if (modal === 'modal-select-warehouse') {
        this.responsiveSet('warehouse_id', data['warehouse_id'])
        this.responsiveSet('warehouse_name', data['warehouse_name'])
      }
      this.$bvModal.hide(modal)
    },
    responsiveSet(key, value) {
      this.$set(this.inbound, key, value)
    },
    /*showModal() {
      this.$refs['modal'].show()
    },*/
    /*toggleDetail(data) {
      data.toggleDetails()
    },*/
    onSelectProducts() {
      let selected = this.$refs.myProductSelect.getSelected()

      selected.forEach(item => {
        const newData = {
          id: 'new' + (this.itemList.length - 1),
          product_id: item.product_id,
          product_name: item.name,
          product_code: item.code,
          inboundDetails: [{ status: 0 }],
          product: item,
          _showDetails: true
        }
        this.itemList.push(newData)
      })

    },
    async validateAll() {
      let count = 0
      let items = this.itemList
      for (let i = 0; i < items.length; i++) {
        if (await this.$refs[`inboundForm${i}`].$refs.observer.validate()) {
          count++
        }
      }
      return count === items.length
    },

    //选择签约主体
    showCompany() {
      if(this.canEditFlag){
        this.$refs['companyModal'].show()
      }
    },
    onSelectSingle() {
      let data = this.$refs.companySelect.getSelected()[0]
      this.responsiveSet('company_id', data['company_id'])
      this.responsiveSet('company_name', data['company_name'])
    },
  },
  created() {
    const userData = getUserData()
    this.user = userData
    let inboundId = this.$route.query.inboundId || 0;
    if(inboundId != 0){
      this.newPageFlag = false;
    }else{
      this.newPageFlag = true;
    }
    this.edit(inboundId);

  },
  setup: function (props) {

    const toast = useToast()
    const {
      route,
        router
    } = useRouter()
    /*let inbound_id
    if (props.inboundId != null) {
      inbound_id = props.inboundId
    } else {
      inbound_id = route.value.query.id || 0
    }*/
    /*const warehouseId = ref(parseInt(route.value.query.warehouseId) || 0)*/
    // Register module
    if (!store.hasModule('inboundEdit')) store.registerModule('inboundEdit', inboundEditStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('inboundEdit')) store.unregisterModule('inboundEdit')
    })

    //返回
    const cancel = function () {
      this.$router.go(-1)
    }

    //审批通过、驳回
    const approval = async function (status) {
      this.$refs['loadingModal'].show()
      const params = {...this.inbound,item_list:[],status:status}
      this.itemList.forEach(item =>{
        let temp = {}
        temp.product_id = item.product_id
        temp.inbound_details = item.inboundDetails
        temp.purchase_team_id = item.purchaseTeam
        params.item_list.push(temp)
      })
      store.dispatch('inboundEdit/approval', params)
          .then(res => {
            this.$refs['loadingModal'].hide()
            if (res.data.code === 0) {
              toast.success(res.data.data)
              router.push({name: 'apps-inbound-list'})
            } else {
              toast.error(res.data.data)
            }
          })
    }

    //
    const onChangeWarehouse = function () {
      this.$forceUpdate()
    }
    const save = async function (status) {
      if (isEmpty(this.inbound.warehouse_id)){
        toast.error("请选择仓库")
        return
      }
      if (!this.inbound.arrival_time){
        toast.error("请填写入库日期")
        return
      }
      if (isEmpty(this.inbound.company_id)){
        toast.error("请选择签约主体")
        return
      }
      if (this.itemList.length === 0){
        toast.error("请填写明细")
        return
      }
      const success = await this.validateAll()
      if (!success){
        toast.error("请按照提示正确填写")
        return
      }
      for (let i = 0; i < this.itemList.length; i++) {
        if (isEmpty(this.itemList[i].purchaseTeam)){
          toast.error("请填写销售权")
          return
        }
      }
      this.$refs['loadingModal'].show()

      const params = {...this.inbound,item_list:[],status:status}
      this.itemList.forEach(item =>{
        let temp = {}
        temp.product_id = item.product_id
        temp.product_name = item.product_name,
        temp.product_code = item.product_code,
        temp.product = {guarantee_period:item.product.guarantee_period,guarantee_period_unit:item.product.guarantee_period_unit}
        temp.inbound_details = item.inboundDetails
        temp.purchase_team_id = item.purchaseTeam
        params.item_list.push(temp)
      })
      params.detail = JSON.stringify(params.item_list)
      store.dispatch('inboundEdit/save', params)
          .then(res => {
            this.$refs['loadingModal'].hide()
            if (res.data.code === 0) {
              toast.success(res.data.data)
              router.push({name: 'apps-inbound-list'})
            } else {
              toast.error(res.data.data)
            }
          })
    }

    const hideAndRefetchData = function (data) {
      data.toggleDetails()
      refetchData()
    }

    const edit = function (inboundId) {
        store.dispatch('inboundEdit/initEdit', {inboundId: inboundId}).then(res => {
          this.inbound = res.data.data
          //新增
          if(inboundId == 0){
            this.responsiveSet('company_id', 61)
            this.responsiveSet('company_name', "浙江云贸科技有限公司")
            this.canEditFlag = true;
            this.inbound.warehouse_id = this.warehouseOptions.length==0?null:parseInt(this.warehouseOptions[0].value)
          }else{
            if(this.inbound.status == 2||this.inbound.status == 3){
              this.canEditFlag = false;
            }
            //回显明细
            let details = JSON.parse(res.data.data.detail);
            details.forEach(item => {
              const newData = {
                id: 'new' + (this.itemList.length - 1),
                product_id: item.product_id,
                product_name: item.product_name,
                product_code: item.product_code,
                purchaseTeam: parseInt(item.purchase_team_id),
                inboundDetails: item.inbound_details,
                product: item.product,
                _showDetails: true
              }
              this.itemList.push(newData)
            })
            this.$forceUpdate()
          }
        })
    }

    const {
      tableColumns,
      refListTable,
      refetchData,

    } = inboundEdit({
      /*inbound_id: inbound_id*/
    })

    const { user_id } = getUserData()
    const { level } = getUserData()
    const onUploaded = function (id, attachment, result) {
      this.inbound[id] = result
    }
    return {
      tableColumns,
      refListTable,
      refetchData,
      edit,

      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      save,
      approval,
      cancel,
      onChangeWarehouse,
      isEmpty,
      /*warehouseId,*/
      hideAndRefetchData,
      user_id,
      level,
      onUploaded
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
