import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/inbound/save', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    approval(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/inbound/approval', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    initEdit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/inbound/initEdit', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
